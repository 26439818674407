
import { useStore } from "vuex";
import { watch, computed, defineComponent, reactive } from "vue";
import { pageFilter } from "@/components/Table/ts/hooks/useAppTable";
import { operatorFilterActives } from "@/enums/operators/operatorFilterActives";
import { IUserAccount } from "@/store/interfaces/accounts/IUserAccount";
import {
  IUseBaseAppLayout,
  useBaseAppLayout
} from "@/hooks/layout/useBaseAppLayout";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import ShadowControl from "@/components/Form/ShadowControl.vue";
import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";
import CreateUserDialog from "@/components/ui/Modal/Dialog/instances/CreateUserDialog.vue";
import { userId } from "@/store/interfaces/auth/common";
import { sortOrders } from "@/enums/main/sortOrders";
import { IObjectKeys } from "@/interfaces/IObjectKeys";
import DeleteUserDialog from "@/components/ui/Modal/Dialog/instances/DeleteUserDialog.vue";
import OperatorsTable, {
  operatorsTableSort
} from "@/components/Table/ts/instances/operators/OperatorsTable.vue";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import { useUserTable } from "@/hooks/tables/modules/usersTable/usersTable";
import { AddUserModalDto, userTypes } from "@/api/interfaces/users/users.dto";
import { useUsers } from "@/hooks/users/useUsers";
import { createUser } from "@/hooks/users/modules/createUser";

const AccountsSortKeys = {
  name: "name",
  date: "date"
};

export const accountsSortKeys: IObjectKeys = [
  AccountsSortKeys.name,
  AccountsSortKeys.date
];

const filters = [
  operatorFilterActives.active,
  operatorFilterActives.inActive
];

const initialSelectedUser = {
  id: null,
  name: null,
  email: null,
  avatar: null
};

export default defineComponent({
  name: "Operators",
  components: {
    AppTableController,
    DeleteUserDialog,
    CreateUserDialog,
    BtnContent,
    AppButton,
    SelectDropdown,
    ShadowControl,
    PageMainHeading,
    OperatorsTable
  },

  setup() {
    const store = useStore();

    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10
    });

    // Page layout refs
    const layoutRefs: IUseBaseAppLayout = useBaseAppLayout({
      initialSearchQuery: "",
      filterOptions: filters,
      onFiltersChange: () => requestAccountsHandler(true, fullFilter.value),
      onChangeSearchQuery: () => {
        changeViewConfigurationHandler({
          page: 1,
          perPage: viewConfiguration.perPage
        });
      }
    });

    watch(viewConfiguration, () => requestAccountsHandler());

    const { filter, searchQuery } = layoutRefs;

    const selectedFilterName = computed((): string => {
      if (!filter) return "";

      return (
        filter.find(({ selected }: pageFilter) => selected)?.name ||
        operatorFilterActives.active
      );
    });

    const fullFilter = computed(() => {
      return {
        text: searchQuery.value,
        sortSpec: selectedFilterName.value == "Active" ? "Active" : "In Active",
        sortUser: "Recently created",
        type: "operator",
        filterList: {}
      };
    });

    const options = {
      viewConfiguration,
      filter: fullFilter.value
    };

    const { requestAccountsHandler, changeViewConfigurationHandler } = useUsers(
      options
    );
    requestAccountsHandler();
    const createUserHandler = async (data: any) => {
      return await createUser(data, store);
    };
    return {
      ...layoutRefs,
      ...useUserTable(),
      viewConfiguration,
      searchQuery,
      accountsList: computed(() => store.getters.accountsList),
      totalAccountsCount: computed(() => store.getters.totalAccounts),
      createUserHandler,
      requestAccountsHandler,
      changeViewConfigurationHandler
    };
  },

  data() {
    return {
      createUserDialogOpened: false,
      deleteUserDialogOpened: false,
      selectedUser: initialSelectedUser,
      sortConfig: {
        byName: { order: sortOrders.turnOff, keyName: "fullName" },
        byDateCreated: { order: sortOrders.turnOff, keyName: "dateCrearted" }
      },
      isLoading: false
    } as {
      createUserDialogOpened: boolean;
      deleteUserDialogOpened: boolean;
      sortConfig: operatorsTableSort;
      selectedUser: {
        id: userId;
        name: string | null;
        email: string | null;
        avatar: string | null;
      };
      isLoading: boolean;
      deleteLoading: Boolean;
    };
  },

  computed: {
    emptyText(): string {
      if (!this.searchQuery) {
        return "There are no accounts created yet!";
      }

      return `No users found by request "${this.searchQuery}"`;
    }
  },
  methods: {
    userToggleBlockHandler() {},

    deleteUserHandler(id: number) {
      const account: IUserAccount | null =
        this.accountsList.find(
          ({ id: accountId }: IUserAccount) => id === accountId
        ) || null;

      if (account !== null) {
        const { email, fullName: name, image: avatar } = account;
        this.selectedUser = { id, email, name, avatar };

        this.deleteUserDialogOpened = true;
      }
    },

    onDeleteCancel() {
      this.onDeleteEnd();
    },

    onDeleteEnd() {
      this.selectedUser = initialSelectedUser;
      this.deleteUserDialogOpened = false;
    },

    async onUserCreateSubmit(data: AddUserModalDto): Promise<boolean> {
      this.isLoading = true;
      const result = await this.createUserHandler({
        ...data,
        type: userTypes.operator
      });
      this.isLoading = false;
      if (result) {
        this.hideHandler();
        this.requestAccountsHandler?.();
      }
      return result;
    },
    onDeleteConfirm() {
      this.deleteUser(() => {
        this.requestAccountsHandler?.();
      });
    },

    toggleCreateOperatorDialog(status = true) {
      this.createUserDialogOpened = status;
    },

    showNotification(message: string): void {
      this.$store.dispatch("showErrorNotification", message);
    },
    hideHandler() {
      console.log("Hide");
      this.createUserDialogOpened = false;
    }
  }
});
